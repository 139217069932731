import React, { useState, useEffect } from 'react';
import ShareButton from '../../assets/share.png';
import copyLink from '../../assets/link.png';
import XShare from '../../assets/x.png';
import FacebookShare from '../../assets/facebook.png';
import { apiUrl } from '../../axios/ApiUrl';
import axios from 'axios';

const ReadStories = () => {
  const [stories, setStories] = useState([]);
  const [showShare, setShowShare] = useState(false);
  const [shareableLink, setShareableLink] = useState('');

  useEffect(() => {
    const fetchStories = async () => {
      try {
        const response = await axios.get(`${apiUrl}users/anonymous/stories`);
        setStories(response.data.data);
        console.log(response.data.data);
      } catch (error) {
        console.error("Error fetching stories:", error);
      }
    };

    fetchStories();
  }, []);

  // Function to handle the copy link action
  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      alert('Link copied to clipboard');
    }).catch((error) => {
      console.error('Failed to copy:', error);
    });
  };

  // Function to handle the share on X (Twitter)
  const shareOnTwitter = (storyLink) => {
    const twitterShareUrl = `https://twitter.com/intent/tweet?text=Check%20out%20this%20story:%20${encodeURIComponent(storyLink)}`;
    window.open(twitterShareUrl, '_blank');
  };

  // Function to handle the share on Facebook
  const shareOnFacebook = (storyLink) => {
    const facebookShareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(storyLink)}`;
    window.open(facebookShareUrl, '_blank');
  };

  return (
    <div className="lg:pt-[7%] md:pt-[10%] pt-[30%] pb-[5%]">
      <div>
        <div className='read_story_header flex justify-center w-full py-[5%]'>
          <p className='lg:text-[56px] md:text-[44px] text-[32px] font-semibold w-full lg:w-[60%]
            text-center text-blue-900'>Finding Strength. Read Other Justice Stories</p>        
        </div>

        <div className='read_stories_body w-full px-8 '>
          <div className='read_stories_container grid lg:grid-cols-2 md:grid-cols-2 gap-5 relative'>
            {stories.map((story, index) => {
              const storyLink = `${window.location.origin}/stories/${story._id}`;
              return (
                <div
                  className='read_stories_container_body cursor-pointer bg-gray-200 hover:bg-blue-900 hover:text-gray-100 hover:font-medium rounded-lg p-6'
                  key={index}
                >
                  <p className='leading-28'>{story.location}</p>
                  <p className='leading-28'>{story.your_story}</p>
                  <img
                    src={ShareButton}
                    alt='Share'
                    className='mt-4 cursor-pointer'
                    onClick={() => {
                      setShareableLink(storyLink);
                      setShowShare(!showShare);
                    }}
                  />
                </div>
              );
            })}
            {showShare && (
              <div className='absolute lg:ml-[4%] lg:mt-[7%] md:ml-[8%] md:mt-[26%] ml-[13%] mt-[50%] py-3 bg-white flex flex-col gap-4'>
                <div
                  className='flex gap-3 items-center border-b-2 border-gray-200 p-2 cursor-pointer'
                  onClick={() => copyToClipboard(shareableLink)}
                >
                  <img src={copyLink} alt='Copy Link' />
                  <p>Copy link</p>
                </div>

                <div
                  className='flex gap-3 items-center border-b-2 border-gray-200 p-2 cursor-pointer'
                  onClick={() => shareOnTwitter(shareableLink)}
                >
                  <img src={XShare} alt='Share on X' />
                  <p>Share on X</p>
                </div>

                <div
                  className='flex gap-3 items-center p-2 cursor-pointer'
                  onClick={() => shareOnFacebook(shareableLink)}
                >
                  <img src={FacebookShare} alt='Share on Facebook' />
                  <p>Share on Facebook</p>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReadStories;
