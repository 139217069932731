import React, {useState} from 'react'
import LegalTechFest from '../../assets/legaltech.png'
import { IoLocationOutline } from "react-icons/io5";

const LegalTech = () => {

    return (
    <div className="lg:pt-[7%] md:pt-[20%] pt-[30%] pb-[5%]">
        <div className='legal_tech_body w-full flex px-[10%] lg:flex-row flex-col-reverse gap-5'>
            <div className='legal_tech_left lg:w-1/2 w-full flex flex-col gap-5 justify-center'>
                <p className='px-3 rounded-full py-2 bg-gray-300 text-sm lg:w-[35%]
                md:w-[40%] w-1/2 text-center'>Legal Tech Fest 1.0</p>

                <p className='text-blue-950 text-5xl font-bold'>Beyond The Courtroom:
                    Expanding Access to Justice
                    with Technology
                </p>

                <div className='legal_tech_right lg:w-1/2 w-full lg:hidden md:hidden flex flex-col gap-5'>
                    <img src={LegalTechFest} alt='' />
                </div>

                <div className='date_and_time flex gap-2'>
                    <div>
                        <p className='bg-gray-300 text-xs text-gray-600 text-center px-3'>JUL</p>
                        <p className='border-2 px-3 font-semibold'>27</p>
                    </div>

                    <div>
                        <p className='text-md'>Saturday 27, July</p>
                        <p className='text-gray-600'>10:00 - 16:00</p>
                    </div>
                </div>

                <div className='address_container flex gap-3 items-center'>
                    <div>
                        <IoLocationOutline className='w-8 h-8'/>
                    </div>

                    <div>
                        <p>Lagos, Nigeria</p>
                        <p className='text-gray-600'>Register to get address</p>
                    </div>
                </div>

                <a href='https://lu.ma/eafqo787'>
                    <div className='register_now_button bg-blue-950 w-2/3 mt-4 text-white py-3 rounded-md text-center'>
                        <p>Register Now</p>
                    </div>
                </a>
            </div>

            <div className='legal_tech_right lg:w-1/2 w-full lg:flex md:flex hidden flex-col gap-5'>
                <img src={LegalTechFest} alt='' />
            </div>
        </div>
    </div>
  )
}

export default LegalTech