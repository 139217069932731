import React, { useState} from 'react'
import BodyImage from '../../assets/homeBody.png'
import BodyVector from '../../assets/homeVector.png'
import SendButton from '../../assets/send.png'
import MessageSent from '../MessageSent/MessageSent'
import Nav from '../Nav/Nav'
import ReadStories from '../ReadStories/ReadStories'
import axios from 'axios'
import { apiUrl } from '../../axios/ApiUrl'
import Support from '../Support/Support'
import Partners from '../Partners/Partners'
import LegalTech from '../LegalTech/LegalTech'
import Policies from '../Policies/Policies'
import Footer from '../Footer/Footer'


const Body = () => {

  const [activeStoryClass, setActiveStoryClass] = useState('message-form');
  const [activeNav, setActiveNav] = useState('share-story')
  const [loading, setLoading] = useState(false);

  const [bodyData, setBodyData] = useState({
    location: "",
    your_story: "",
  });

  const bodyChange = (event) => {
      setBodyData({...bodyData, [event.target.name]: event.target.value})
  }  

  const handleMessageSubmit = async (e) => {
    e.preventDefault();

    if (!bodyData.your_story) {
      alert('Please fill in all fields');
      return;
    }

    setLoading(true);

    try {
      await axios.post(`${apiUrl}users/anonymous-message`, bodyData);
      setActiveStoryClass('message-sent');
    } catch (error) {
      console.error('Sending Story Error', error);
    } finally {
      setLoading(false);
    }
  };
  
  return (
    <>
      <div>
        <div >
          <Nav activeNav={activeNav} setActiveNav={setActiveNav} />
            <div className={activeNav === 'share-story' ? `lg:pt-[10%] md:pt-[15%] sm:pt-[30%] max-sm:pt-[30%] flex flex-col items-center text-center py-4  lg:bg-white md:bg-white sm:bg-gray-50` : `hidden`}>
            <div className='relative w-full'>
                <div className='relative z-30'>
                  <p className='lg:text-[72px] md:text-[64px] text-[32px] font-semibold text-center leading-tight
                  text-blue-950 w-full lg:px-[8%] xl:px-[15%] 2xl:px-[15%] sm:px-[15%] max-sm:px-[3%] max-sm:mb-3 md:px-[10%]'>Your Voice Matters. Share Your <span className='text-blue-700'>Justice Story</span></p>
                </div>
              <div>
                <div className='w-full relative'>
                  <img src={BodyVector} alt='' className='z-10 absolute 2xl:hidden xl:flex lg:flex max-sm:hidden sm:hidden left-[38%] bottom-[10%] object-contain' />
                </div>
          </div>
              <p className='text-[20px] font-normal justify-center w-full lg:px-[20%] sm:px-[15%] md:px-[10%] xl:flex 2xl:flex lg:flex md:flex hidden mt-[2%] '>Have you experienced a sense of injustice or ongoing harm in your life?
                  Share your story with us anonymously, and let us connect you to the right support
                </p>
          </div>
          
          <div className='relative w-full flex justify-center pt-[6%]'>
            <div className={activeStoryClass === 'message-form' ? `body_form absolute top-[20%] p-6 py-10 border-2 border-gray-950
                w-[85%] bg-white rounded-lg lg:w-[40%] md:w-[60%]` : `hidden`} >
                    <form className='flex flex-col gap-12' onSubmit={handleMessageSubmit}>
                      <div className='location_container'>
                        <p className='absolute ml-[3%] -mt-[2%] bg-white'>Location</p>
                        <input type='text'
                        placeholder='Ikeja, Lagos'
                        className='outline-none rounded-lg border-2 border-gray-950 p-3 py-4 w-full'
                        name='location'
                        value={bodyData.location}
                        onChange={bodyChange}    
                        required
                        />
                      </div>
                            
                      <div className='story_container'>
                      <p className='absolute ml-[3%] -mt-[2%] bg-white'>Your Story</p>
                      <textarea 
                        placeholder='Share your justice story'
                        required
                        className='outline-none rounded-lg border-2 border-gray-950 p-3 w-full lg:h-[30vh] h-[15vh]'
                        name='your_story'
                        value={bodyData.your_story}
                        onChange={bodyChange}
                      />
                      </div>
                      
                      <div className='submit_button '>
                        <button type='submit' className='bg-gray-950 w-full flex gap-3
                              items-center justify-center p-3 rounded-lg'>
                          <img src={SendButton} alt='' />
                          <p className='text-white font-semibold text-[16px]'>{loading ? 'Sending...' : 'Send'}</p>
                          {/* <p className='text-white font-semibold text-[16px]'>Send</p> */}
                        </button>
                      </div>
                    </form>
              
                          
                  </div>
                </div>
                <div className={activeStoryClass === "message-sent" ? `flex w-[80%]` : `hidden w-full`}>
                  <MessageSent setActiveNav={setActiveNav}/>
                </div>
              <img src={BodyImage} alt='' className='lg:mt-[3%] md:mt-[23%] mt-[65%] mx-5 object-contain 2xl:w-[50%] w-[85%]'/>

              <div className='share-story-part'>
                  
              </div>
          </div>
        </div>
          <div className={activeNav === 'read-story' ? `flex w-full flex-col` : `hidden`}>
            <ReadStories />
          </div>

          <div className={activeNav === 'our-partners'? `flex w-full flex-col` : `hidden`}>
              <Partners />
          </div>

          <div className={activeNav === 'tech-fest'? `flex w-full flex-col` : `hidden`}>
              <LegalTech />
          </div>

          <div className={activeNav === 'support'? `flex w-full flex-col` : `hidden`}>
              <Support />
          </div>

          <div className={activeNav === 'policies'? `flex w-full flex-col` : `hidden`}>
            <Policies />
          </div>
        </div>
        <Footer activeNav={activeNav} setActiveNav={setActiveNav} />
    </>
  )
}

export default Body