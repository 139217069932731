import React, {useState} from 'react'
import SupportImage from '../../assets/support_image.jpeg'

const Policies = () => {


    return (
    <>
      <div className="lg:pt-[7%] md:pt-[10%] pt-[30%] pb-[5%] px-[10%] flex flex-col gap-5 prose text-justify">
          <p className='text-center text-4xl font-bold text-blue-800'>TERMS AND CONDITIONS</p>

          <div>
            <div className='effective_date flex gap-2'>
              <p className='font-bold'>Effective Date:</p>
              <p>1st July, 2024</p>
            </div>

            <p className='mt-5'>
              Welcome to the Access to Justice Awareness Campaign
              (Tell us Your Justice Story). This campaign is organized
              by Legum Limited (Legum) in partnership with other
              relevant stakeholders to promote awareness on individual
              justice rights and connect them with free legal advice and instant support. 
              By participating in this campaign, you agree to comply
              with and be bound by the following terms and conditions
              (‘Terms’). Please read these Terms carefully before
              participating in the campaign and sharing your justice story.
            </p>
          </div>

          <div className='eligibility_container'>
            <p className='font-bold'>1. Eligibility</p>

            <ul>
              <li>
                This campaign is open to all individuals who are legal
                residents of Nigeria and are at least 18 years of age.
              </li>

              <li>
                Minors and Children below the age of 18 years are
                prohibited from participating in the campaign.
              </li>
            </ul>
          </div>

          <div className='privacy_container'>
            <p className='font-bold'>2. Campaign Participation</p>

            <div>
              <ul>
              <li>
                To participate in the Campaign, individuals must
                submit their justice stories through the designated
                online platform or other approved methods as announced by Legum.
              </li>
              <li>
                Participants must provide accurate and complete information when submitting their stories.
              </li>
              <li>
                Submissions must be original works and not infringe on any third-party rights.
              </li>
              <li>
                Participants should ensure that their personally identifiable information including
                name, address, phone number, email address, or the personally identifiable
                information of another person is not disclosed during the submission process
                unless they are subscribing to a partner service. 
              </li>
              </ul>
            </div>
          </div>

          <div className='submission_container'>
            <p className='font-bold'>3. Use of Submission</p>

            <div>
              <ul>
                <li>
                  By submitting a story, participants grant Legum and
                  its partners a non-exclusive, royalty-free, worldwide,
                  perpetual license to use, reproduce, distribute,
                  and display the submission in connection with the
                  Campaign and related promotional activities.
                </li>

                <li>
                  Participants agree that Legum may edit, modify,
                  or adapt submissions for clarity, length, or compliance with
                  legal and ethical standards.
                </li>
              </ul>
            </div>
          </div>

            <div>
              <p className='font-bold'>4. Data Privacy and Protection </p>

              <div>
                <ul>
                  <li>
                    Legum is committed to protecting the privacy of participants.
                    Any information collected during the Campaign will be used solely
                    for the purposes of the Campaign and in accordance with Legum's
                    Data Protection Policy.
                  </li>

                  <li>
                    Participants consent to the collection, use, and disclosure of
                    their personal information as described in the Data Protection Policy.
                  </li>

                  <li>
                    Participants should ensure to read the privacy policies of our partners
                    before requesting or accessing support.
                  </li>
                </ul>
              </div>
            </div>

              <div>
                <p className='font-bold'>5. Limitation of Liability</p>

                <div>
                  <ul>
                    <li>
                      Legum and its partners are not responsible for any incorrect or
                      inaccurate information, technical failures, unauthorized access
                      to, or theft, or destruction of submissions, or any other
                      errors or malfunctions.
                    </li>

                    <li>
                      Participants agree to release and hold harmless Legum, its
                      partners, and their respective officers, directors, employees,
                      and agents from any and all claims, damages, or liabilitie
                      arising from or related to their participation in the Campaign.
                    </li>

                    <li>
                      Participants agree to hold harmless and indemnify Legum from
                      any and all claims, damages, or liabilities arising from
                      or related to the use of a partnered service.
                    </li>
                  </ul>
                </div>
              </div>

              <div>
                <p className='font-bold'>6. Disqualification</p>

                <div>
                  <ul>
                    <li>
                      Legum reserves the right to disqualify any participant who
                      violates these Terms or engages in any conduct that Legum
                      deems inappropriate, unethical, or harmful to the integrity
                      of the Campaign.
                    </li>

                    <li>
                      Legum reserves the right to delete or restrict any story that
                      displays the personally identifiable data of any person
                      including but not limited to name, address, email address,
                      and phone number.
                    </li>
                  </ul>
                </div>
              </div>

              <div>
                <p className='font-bold'>7. Changes to the Terms</p>

                <p>
                  Legum reserves the right to modify or
                  terminate the Campaign and these Terms at any time,
                  without prior notice. Any changes will be effective
                  immediately upon posting on the Campaign website
                  or other communication channels.
                </p>
              </div>

              <div>
                <p className='font-bold'>8. Governing Law</p>

                <ul>
                  <li>
                    These Terms shall be governed by and construed in accordance
                    with the laws of the Federal Republic of Nigeria.
                  </li>

                  <li>
                    Any disputes arising out of or in connection with these
                    Terms or the Campaign shall be subject to the exclusive
                    jurisdiction of the courts of Nigeria.
                  </li>
                </ul>
              </div>

              <div>
                <p className='font-bold'>9. Contact Information</p>

                <div className='flex flex-col gap-3'>
                  <p>For any questions or concerns regarding these Terms or the Campaign, please contact Legum Limited at:</p>
                  <p><b>Email:</b> hello@legum.tech</p>
                  <p><b>Address:</b> No 5 Kwaji Close, Maitama Abuja, Nigeria.</p>
                  <p>
                    By participating in the Access to Justice Awareness
                    Campaign, you acknowledge that you have read, understood,
                    and agree to be bound by these Terms and Conditions.
                  </p>
                </div>
              </div>
              <div className='main_policies mt-[3%] flex flex-col gap-4'>
                <p className='text-4xl font-bold text-blue-800 text-center'>DATA PROTECTION POLICIES</p>

                <p className='mt-5'>Legum Limited ("we," "us," or "our") is committed to protecting the
                  privacy and personal data of participants in the Access to Justice
                  Awareness Campaign ("Campaign"). This Data Protection Policy outlines
                  how we collect, use, disclose and protect your personal data in
                  compliance with applicable data protection laws. 
                  By participating in this campaign, you agree to the
                  processing of your Personal Data and be bound by the
                  following terms within this Data Protection Policy.
                  (‘Policy’). Please read this Policy carefully before
                  participating in the campaign and sharing your justice story. 
                </p>

                <div className='data_collection_one'>
                  <p className='font-bold'>1. Data Collection</p>

                  <p>
                    To participate on the Campaign, we need your consent to
                    collect and process your personal data. We will solely
                    collect data that is necessary, accurate and restricted
                    to the purpose of the Campaign. We will collect the
                    following types of personal data from you.
                  </p>

                    <li>Location</li>
                    <li>Justice story submission</li>
                    <li>Any other information you voluntarily provide when you engage with
                      any of our partners
                    </li>

                  <p className='mt-4'>We collect personal data through the following platforms:</p>
                    <li>Social Media</li>
                    <li>Online Submission forms</li>
                    <li>#A2J Campaign website</li>
                    <li>Through our partnered services</li>
                </div>

                <div className='personal_data_two'>
                  <p className='font-bold'>2. Use of Personal Data</p>

                  <div>
                    <p>
                      We may pseudonymize, anonymize and aggregate
                      data for research, analysis, and reporting
                      purposes to understand the impact and reach
                      of the Campaign. We may use your personal
                      data for the following purposes:
                    </p>

                      <li>To promote, manage and administer the Campaign in accordance with its objectives.</li>
                      <li>To communicate with participants regarding their submissions and the Campaign.</li>
                      <li>To connect participants with legal support from our partner.</li>
                      <li>To provide participants with information and updates related to the Campaign.</li>
                      <li>To comply with legal and regulatory requirements.</li>
                  </div>
                </div>

                <div className='disclosure'>
                  <p className='font-bold'>3. Disclosure of Personal Data</p>

                  <div>
                    <li>We may share your personal data with our
                      partners, collaborators and service providers
                      involved in the campaign to monitor,
                      measure and evaluate the campaign
                      activities and impact. 
                    </li>

                    <li>We may disclose your information to
                      legal and regulatory authorities 
                      if required by the law and/or to
                      protect our rights and interests.
                    </li>

                    <li>We do not sell, rent or trade your personal
                      data to third parties for marketing purposes.
                    </li>
                  </div>
                </div>

                <div className='data_security'>
                    <p className='font-bold'>4. Data Security</p>

                    <div>
                      <li>We have implemented appropriate technical
                        and organizational measures to protect
                        personal data against unauthorized access,
                        alteration, disclosure, or destruction.
                      </li>

                      <li>Our partners are contractually bound to
                        maintain the highest integrity when
                        processing the personal data of all participants.
                      </li>

                      <li>Access to personal identifiable data
                        is prohibited unless the participate
                        has indicated consent through the
                        use of partnered service.
                      </li>
                    </div>
                </div>

                <div className="data_retentions">
                  <p className='font-bold'>5. Data Retention</p>
                  <p>
                    We retain personal data only for as long as necessary to
                    fulfill the purposes for which it was collected
                    during the Campaign and in compliance with
                    regulatory requirements. You may chose to
                    delete or change your data but note that
                    certain anonymized information may be
                    retained within our database for
                    recordkeeping purposes.
                  </p>
                </div>

                <div className='data_subject'>
                  <p className='font-bold'>6. Your Rights as a Data Subject</p>

                  <p>As a participatant to the Access to
                    Justice Awareness Campaign, you
                    have the following rights regarding
                    the use of your personal data.
                  </p>

                  <li>The right to access and obtain a copy of your personal data.</li>
                  <li>The right to request correction of inaccurate or incomplete personal data.</li>
                  <li>The right to request deletion of your personal data, subject to certain exceptions.</li>
                  <li>The right to object to the processing of your personal data for specific purposes.</li>
                  <li>The right to withdraw consent to the processing of your personal data.</li>
                </div>
                
                <div className="changes_policy">
                  <p className='font-bold'>7. Changes to this Policy</p>

                  <p>We may update this Data Protection Policy from time to time.
                    Any changes will be effective immediately upon posting the
                    revised policy on our website or other communication channels.
                  </p>
                </div>

                <div>
                <p className='font-bold'>8. Contact Information</p>

                <div className='flex flex-col gap-3'>
                  <p>For any questions or concerns regarding these Data Protection Policy , please contact Legum Limited at:</p>
                  <p><b>Email:</b> hello@legum.tech</p>
                  <p><b>Address:</b> No 5 Kwaji Close, Maitama Abuja, Nigeria.</p>
                  <p>
                    By participating in the Access to Justice Awareness
                    Campaign, you acknowledge that you have read, understood,
                    and agree to be bound by these Terms and Conditions.
                  </p>
                </div>
              </div>
              </div>
      </div>

    </>
  )
}

export default Policies