import React from 'react'
import SentMark from '../../assets/sent.png'

const MessageSent = ({ setActiveNav }) => {
  const switchToPartners = () => {
    setActiveNav('our-partners')
  }
  
  return (
    <div>
          <div className='body_form absolute lg:mt-[3%] p-6 py-10 border-2 border-gray-950
          bg-white rounded-lg lg:w-[60%] md:w-[60%] lg:ml-[12%] md:ml-[12%] ml-[-4%] flex flex-col gap-20 sm:w-[90%]'>
                <div type='submit' className='border-2 border-gray-950 w-full flex gap-3
                        items-center justify-center p-3 rounded-lg'>
                    <img src={SentMark} alt='' />
                    <p className='font-semibold text-[16px]'>Sent</p>
                </div>

                <div className='flex gap-5 w-full' >
                  <div className='legal_advice bg-blue-900 text-white
                    p-3 w-1/2 flex text-center justify-center rounded-lg'>
                      <a href="https://onelink.to/bkvw4x">
                        <p>Get free legal advice</p>
                      </a>
                    </div>

                  <div className='legal_support bg-green-600
                    p-3 w-1/2 text-white flex text-center justify-center rounded-lg' onClick={switchToPartners}>
                        <p>Get instant legal support</p>
                    </div>
                </div>
          </div>    
    </div>
  )
}

export default MessageSent