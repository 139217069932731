import React, {useState} from 'react'
import SupportImage from '../../assets/support_image.jpeg'

const Support = () => {


    return (
    <div className="lg:pt-[7%] md:pt-[10%] pt-[30%] pb-[5%] px-3 w-full">
        <p className='font-bold lg:text-6xl md:text-5xl text-3xl lg:text-center md:text-center
        text-blue-950 my-4 text-center'>Support the #A2J Campaign</p>

        <p className='lg:text-center md:text-center w-full
         lg:px-[25%] md:px-[18%] px-[6%] text-justify text-sm mt-4'>Access to justice, an integral human rights, plays a crucial role in various
            aspect of our lives, from safeguarding our livelihoods and health to promoting
            education and safety. Despite its importance, a significant number of individuals
            remain disenfranchised from  their legal rights, struggle to resolve their legal
            issues, trapping them in perpetual cycles of poverty and injustice that we all
            witness around us each day <br />
        </p>
            <div className='w-full text-sm text-center'>
                <span className='text-blue-950 font-semibold'> ~ Tomi Davies</span>
            </div>

        <div className='support_body w-full flex lg:flex-row flex-col-reverse gap-6 lg:p-12
        md:p-8 p-6'>
            <div className='support_body_left lg:w-1/2 w-full flex flex-col justify-center lg:px-6 md:px-6 px-0 gap-5'>
                <p className='text-blue-950 font-semibold'>Here is how you can support the Access to Justice Awareness Campaign:</p>

                <p><span className='font-bold text-blue-950'>Donate: </span>
                Your financial support will fund the
                campaign efforts and provide legal
                support to indigents. Every contribution, 
                no matter how big or small, can make
                a substantial impact.    
                </p>

                <p><span className='font-bold text-blue-950'>Spread the word: </span>
                Share the Justice stories with your
                friends, family, partners and on
                social media using the #A2J. The
                more people know about the Access
                to Justice Campaign, the greater
                the impact it can have.
                </p>

                <p><span className='font-bold text-blue-950'>Advocate: </span>
                Become an advocate for
                people-centered justice in
                your community. Educate others
                about the importance of Access
                to Justice and the work we are doing.
                </p>

                <p className='font-semibold rounded-lg text-blue-950 p-2 bg-gray-300
                text-sm flex flex-col gap-3'>
                    "Injustice anywhere is a threat to
                    justice everywhere. The time is
                    always right to do what is right."
                    <span className='font-bold text-blue-950 text-xl'> ~ Martin Luther King Jr.</span>
                </p>

                <a href="https://squairr.com/donate/946408101105298?id=395341400114953"><button className='bg-blue-950 text-white w-full rounded-xl py-3'>Donate now</button></a>
            </div>

            <div className='support_image lg:w-1/2 w-full'>
                <img src={SupportImage} alt='' className='object-cover w-full h-auto' />
            </div>
        </div>
    </div>
  )
}

export default Support