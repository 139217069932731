import React from 'react'
import A2J from '../../assets/A2J.png'

const Footer = ({ setActiveNav }) => {
  const moveToPolicies = () => {
    setActiveNav('policies')
  }
  
  return (
   <div className='bg-blue-950 flex lg:flex-row md:flex-row flex-col-reverse gap-6 items-center px-10 justify-between w-full'>
    <div className='lg:w-1/2 md:w-1/2 w-full'>
        <img src={A2J} alt='A2J' className='w-50 h-100'/>
    </div>

    <div className='flex gap-2 text-white lg:w-1/2 md:w-1/2 w-full justify-center'>
        <p className='cursor-pointer'><a href="mailto:hello@legum.tech">Contact Us/Partner with us | </a> </p>
        <p onClick={moveToPolicies} className='cursor-pointer'>Policies</p>
    </div>
   </div>
  )
}

export default Footer

// import React from 'react'
// import Legum from '../../assets/LogoMark.png'
// import LegalNaija from '../../assets/logoNaija.png'
// import HiilInnovations from '../../assets/logoColor.png'
// import IPTCL from '../../assets/IPTCL.png'
// import TechMinded from '../../assets/TechMinded.png'
// import PartnerLogo from '../../assets/partnerLogo.png'
// import Law from '../../assets/Law.png'

// const Footer = () => {
  
//   return (
//     <div className='footer_container lg:px-12 md:px-12 px-6 my-4'>
//         <div className='lg:border-b-4 md:border-b-4 border-b-0 border-t-4 border-gray-200 flex lg:flex-row md:flex-row gap-4 items-center flex-col lg:pb-0 md:pb-0 pb-5'>
//               <div className='footer_left p-5 flex flex-col gap-4 lg:border-r-2
//                 md:border-r-2 border-gray-950 lg:w-1/3 w-full md:w-1/3'>
//                 <p className='text-[16px] font-medium'>Brought to you by:</p>
//                 <div className='flex gap-4'>
//                     <img src={Legum} alt='' className='' />
//                     <p className='text-[24px] font-medium'>Legum</p>  
//                   </div>
                  
//                   <div className='flex gap-4'>
//                     <img src={LegalNaija} alt='' className='' />
//                     <p className='text-[24px] font-medium'>LegalNaija</p>  
//                   </div>
                  
//                   <div className='flex gap-4'>
//                     <img src={HiilInnovations} alt='' className='' />
//                     <p className='text-[24px] font-medium'>HiiL Innovations</p>  
//                 </div>
//             </div>
            
//               <div className='footer_center lg:w-1/3 md:w-1/3 w-full lg:border-t-0 border-y-4
//                 md:border-y-0 lg:border-r-2
//                 md:border-r-2 border-gray-200 flex flex-col p-5 gap-2'>
//                 <p className='text-[16px] font-medium'>Partners</p>  
//                 <div className='grid grid-cols-2 gap-6 w-3/4'>
//                     <div className='border-2 px-3 flex justify-center py-1'>
//                         <img src={IPTCL} alt='' className='object-contain'/>
//                     </div>  
                      
//                     <div className='border-2 px-3 flex justify-center py-2'>
//                         <img src={TechMinded} alt='' className='object-contain'/>
//                     </div> 
                      
//                     <div className='border-2 px-3 flex justify-center py-2'>
//                         <img src={PartnerLogo} alt='' className='object-contain'/>
//                     </div> 
                      
//                     <div className='border-2 px-3 flex justify-center py-2'>
//                         <img src={Law} alt='' className='object-contain'/>
//                     </div> 
//                 </div>
//             </div>

//             <div className='footer_right flex gap-7 justify-start'>
//                 <div className='about_container flex flex-col gap-4'>
//                       <p className='text-[20px] font-medium'>About</p>
//                       <div className='flex flex-col gap-4'>
//                           <p className='cursor-pointer hover:text-blue-950 hover:font-semibold'><a href="https://legum.tech/about">Legum Limited</a></p>
//                           <p className='cursor-pointer hover:text-blue-950 hover:font-semibold'><a href="https://legalnaija.com/">Legal Naija</a></p>
//                           <p className='cursor-pointer hover:text-blue-950 hover:font-semibold'><a href="https://legum.tech/community">Legum youth network</a></p>
//                       </div>
//                 </div>
                
//                 <div className='policies_contiainer flex flex-col gap-4'>
//                     <p className='text-[20px] font-medium'>Policies</p>
//                     <div className='flex flex-col gap-4'>
//                         <p className='cursor-pointer'>Data protection policy</p>
//                         <p className='cursor-pointer'>Terms and conditions</p>
//                         <p className='cursor-pointer'>Contact support </p>
//                     </div>
//                 </div>
//             </div>
//         </div>
//     </div>
//   )
// }

// export default Footer