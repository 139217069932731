import React, {useState} from "react";
import Body from "./components/Body/Body";

function App() {

  return (
    <div>
      <Body />
    </div>
  );
}

export default App;
