import React from 'react'
import TechMindedLawyers from '../../assets/TechMinded.png'
import IPTCL from '../../assets/IPTCL.png'
import PartnerLogo from '../../assets/partnerLogo.png'  
import Law from '../../assets/Law.png'
import logoNaija from '../../assets/logoNaija.png'
import FoundationJustice from '../../assets/foundationJustice.png'
import CeeLogo from '../../assets/cee_logo.png'
import TechLawyered from '../../assets/techLawyered.png'
import DHL from '../../assets/DHL.png'
import Private from '../../assets/Private.png'
import HIL from '../../assets/HIL.png'
import ELA from '../../assets/ELA.jpeg'
import CEPASD from '../../assets/CEPASD.jpeg'
import NUCLAI from '../../assets/NUCLAI.jpeg'

const Partners = () => {
    return (
        <div className="lg:pt-[7%] md:pt-[10%] pt-[30%] pb-[5%] lg:px-[10%] md:px-[10%] px-[4%] text-center">
            <p className='text-3xl font-bold text-blue-950'>Our Partners</p>

            <div className='private_organizations_images grid grid-cols-1 gap-3 justify-between p-3 items-center'>
                <a href="https://squairr.com/donate/946408101105298?id=395341400114953">
                    <div className='border-2 border-gray-300 p-3 flex lg:flex-row md:flex-row flex-col gap-3 justify-center items-center'>
                        <div className='lg:w-[10%] md:w-[10] w-full flex justify-center border-2 border-gray-300 p-2'>
                            <img src={CeeLogo} alt='' className='w-12 h-12'/>
                        </div>
                        <p className='text-justify lg:w-[90%] md:w-[90%] w-full'>
                            <span className='font-bold'>Civily</span> is enabling donations for support of indigents during the #A2J Campaign through their application Squairr.
                        </p>
                    </div>
                </a>

                <div className='p-3 flex lg:flex-row md:flex-row flex-col gap-3 justify-center items-center border-2 border-gray-300'>
                    <div className='lg:w-[10%] md:w-[10] w-full  flex justify-center border-2 border-gray-300 p-2'>
                        <img src={CEPASD} alt='' className='w-15 h-12'/>
                    </div>
                    <p className='text-justify lg:w-[90%] md:w-[90%] w-full'>
                        <span className='font-bold'>(CPEASD)</span> Center for Peace Advocacy and Sustainable Development 
                        is amplifying the #A2J Campaign within youth communities.
                    </p>
                </div>

                <a href="https://onelink.to/bkvw4x">
                    <div className='p-3 flex lg:flex-row md:flex-row flex-col gap-3 justify-center items-center border-2 border-gray-300'>
                            <div className='lg:w-[10%] md:w-[10] w-full  flex justify-center border-2 border-gray-300 p-2'>
                                <img src={DHL} alt='' className='w-15 h-12'/>
                            </div>
                            <p className='text-justify lg:w-[90%] md:w-[90%] w-full'>
                                <span className='font-bold'>DJIL Chambers</span> is working alongside
                                NULAI to support our engagement
                                with paralegals and first responders
                                during the #A2J Campaign.
                            </p>
                    </div>
                </a>

                <a href="https://onelink.to/bkvw4x">
                    <div className='p-3 flex lg:flex-row md:flex-row flex-col gap-3 justify-center items-center border-2 border-gray-300'>
                            <div className='lg:w-[10%] md:w-[10] w-full flex justify-center border-2 border-gray-300 p-2'>
                                <img src={ELA} alt='' className='w-15 h-12'/>
                            </div>
                            <p className='text-justify lg:w-[90%] md:w-[90%] w-full'>
                                <span className='font-bold'>The ELA Initiative</span> is
                                providing access to free legal advice to indigents online
                                through the Legum Mobile Application and offline
                                support to marginalized communities within Lagos.
                            </p>
                    </div>
                </a>

                <a href="https://www.lawyersnownow.com">
                    <div className='border-2 border-gray-300 p-3 flex lg:flex-row md:flex-row flex-col gap-3 justify-center items-center'>
                            <div className='lg:w-[10%] md:w-[10] w-full flex justify-center border-2 border-gray-300 p-2'>
                                <img src={FoundationJustice} alt='' className='w-15 h-12'/>
                            </div>
                            <p className='text-justify lg:w-[90%] md:w-[90%] w-full'>
                                <span className='font-bold'>Headfort Foundation for Justice</span> is providing support
                                to victims of human rights abuse
                                specifically police brutality with free legal support
                                through their app Lawyers NowNow.
                        </p>
                    </div>
                </a>

                <a href="https://www.hiil.org/research/justice-needs-and-satisfaction-in-nigeria/">
                    <div className='p-3 flex lg:flex-row md:flex-row flex-col gap-3 justify-center items-center border-2 border-gray-300'>
                            <div className='lg:w-[10%] md:w-[10] w-full flex justify-center border-2 border-gray-300 p-2'>
                                <img src={HIL} alt='' className='w-15 h-14' />
                            </div>
                            <p className='text-justify lg:w-[90%] md:w-[90%] w-full'>
                                <span className='font-bold'>Hiil Innovation Hub West Africa</span> is providing access to Baseline Assessment Data on Justice Need within Nigeria.
                            </p>
                    </div>
                </a>
                
                <div className='border-2 border-gray-300 p-3 flex lg:flex-row md:flex-row flex-col gap-3 items-center justify-center'>
                    <div className='lg:w-[10%] md:w-[10] w-full flex justify-center border-2 border-gray-300 p-2'>
                        <img src={IPTCL} alt='' />
                    </div>
                    <p className='text-justify lg:w-[90%] md:w-[90%] w-full'><span className='font-bold'>Intellectual Property and Technology Law Club Ibadan: </span>
                        Amplifying youth engagement on the #A2J Campaign within the University of Ibadan.
                    </p>
                </div>

                <a href='https://app.legalnaija.com/ '>
                <div className='p-3 flex lg:flex-row md:flex-row flex-col gap-3 items-center border-2 border-gray-300'>
                    <div className='lg:w-[10%] md:w-[10] w-full flex justify-center border-2 border-gray-300 p-2'>
                        <img src={logoNaija} alt='' className='w-15 h-15'/>
                    </div>
                    <p className='text-justify lg:w-[90%] md:w-[90%] w-full'> 
                        <span className='font-bold'>LegalNaija</span> is connecting users with legal experts at discounted rates during the #A2J Campaign.
                    </p>
                </div>
                </a>

                <div className='p-3 flex lg:flex-row md:flex-row flex-col gap-3 justify-center items-center border-2 border-gray-300'>
                    <div className='lg:w-[10%] md:w-[10] w-full flex justify-center border-2 border-gray-300 p-2'>
                        <img src={NUCLAI} alt='' className='w-15 h-12'/>
                    </div>
                    <p className='text-justify lg:w-[90%] md:w-[90%] w-full'>
                        <span className='font-bold'>(NULAI)</span> The Network of University Legal Aid Institutions
                        is empowering Law Students to participate in the #A2J Campaign
                        as Paralegals using the Legum Mobile Application and Offline
                        as First Responders through the Legal Clinics.
                    </p>
                </div>

                <a href="https://www.ppdc.org/">
                    <div className='p-3 flex lg:flex-row md:flex-row flex-col gap-3 justify-center items-center border-2 border-gray-300'>
                        <div className='lg:w-[10%] md:w-[10] w-full flex justify-center border-2 border-gray-300 p-2'>
                            <img src={Private} alt='' className='w-15 h-14'/>
                        </div>
                        <p className='text-justify lg:w-[90%] md:w-[90%] w-full'>
                            <span className='font-bold'>The Public and Private Development Centre (PPDC)</span> is monitoring and evaluating the impact of the campaign, transforming data into actionable steps.
                        </p>
                    </div>
                </a>

                <a href="https://lu.ma/eafqo787">
                    <div className='p-3 flex lg:flex-row md:flex-row flex-col gap-3 justify-center items-center border-2 border-gray-300'>
                        <div className='lg:w-[10%] md:w-[10] w-full flex justify-center border-2 border-gray-300 p-2'>
                            <img src={TechLawyered} alt='' className='w-12 h-12'/>
                        </div>
                        <p className='text-justify lg:w-[90%] md:w-[90%] w-full'>
                            <span className='font-bold'>Techlawyered</span> is crowning the campaign’s 
                            efforts on the 27th of July, 2024 with
                            a one-day legal tech event themed:
                            "Beyond the Courtroom: Expanding Access to Justice with Technology".
                        </p>
                    </div>
                </a>

                <div className='border-2 border-gray-300 p-3 flex lg:flex-row md:flex-row flex-col gap-3 items-center justify-center'>
                    <div className='lg:w-[10%] md:w-[10] w-full flex justify-center border-2 border-gray-300 p-2'>
                        <img src={TechMindedLawyers} alt='' />
                    </div>
                    <p className='text-justify lg:w-[90%] md:w-[90%] w-full'><span className='font-bold'>Tech-Minded Lawyers Club Obafemi Awolowo University:</span>
                        Amplifying youth engagement on the #A2J Campaign within Obafemi Awolowo University.
                    </p>
                </div>

                <a href="https://onelink.to/bkvw4x">
                    <div className='border-2 border-gray-300 p-3 flex lg:flex-row md:flex-row flex-col gap-3 items-center justify-center'>   
                        <div className='lg:w-[10%] md:w-[10] w-full flex justify-center border-2 border-gray-300 p-2'> 
                            <img src={PartnerLogo} alt='' />
                        </div>
                        <p className='text-justify lg:w-[90%] md:w-[90%] w-full'><span className='font-bold'>Tech-Savvy Lawyers Club Ekiti State:</span>
                        Amplifying youth engagement on the #A2J Campaign within Ekiti State University 
    
                        </p>
                    </div>
                </a>

                <a href="https://onelink.to/bkvw4x">
                    <div className='border-2 border-gray-300 p-3 flex lg:flex-row md:flex-row flex-col gap-3 items-center justify-center'>
                        <div className='lg:w-[10%] md:w-[10] w-full flex justify-center border-2 border-gray-300 p-2'>
                            <img src={Law} alt='' />
                        </div>
                        <p className='text-justify lg:w-[90%] md:w-[90%] w-full'><span className='font-bold'>Law Student’s Society Baze University:</span>
                        Amplifying youth engagement on the #A2J Campaign within Baze University 
                        </p>
                    </div>
                </a>
            </div>
        </div>
    )
}

export default Partners
