import React, {useState, useEffect} from 'react'
import { FaBars } from "react-icons/fa6";
import { MdOutlineCancel } from "react-icons/md";
import { RxCaretRight } from "react-icons/rx";

const Nav = ({activeNav, setActiveNav}) => {

  // const [activeNav, setActiveNav] = useState('share-story')
  const [mobileNav, setMobileNav] = useState(false)

  useEffect(() => {
    if (mobileNav) {
      document.body.classList.add('no-scroll');
    } else {
      document.body.classList.remove('no-scroll');
    }
  }, [mobileNav]);

  const goBackHome = () => {
    window.location.href = '/'
  }
  
  return (
    <div className='fixed w-full z-50'>
      <div className='bg-white flex  w-full xl:justify-between md:justify-center
        lg:justify-center sm:justify-between max-sm:justify-between sm:px-6 max-sm:px-6 py-4 shadow-lg items-center gap-7'>
        <div className='w-[20%] text-center'>
          <p className='text-blue-800 font-semibold'>#Access2Justice</p>
        </div>
        {/* <div> */}
          <div className='hidden 2xl:flex xl:flex lg:flex md:flex' onClick={() => setActiveNav('share-story')}>
            <p className={activeNav === 'share-story' ? `text-blue-900 border-b-4 border-blue-900 font-semibold` : `hover:border-b hover:border-gray-950 cursor-pointer`}>Share your story</p>
          </div>

          <div className='hidden 2xl:flex xl:flex lg:flex md:flex' onClick={() => setActiveNav('read-story')}>
            <p className={activeNav === 'read-story' ? `text-blue-900 border-b-4 border-blue-900 font-semibold` : `hover:border-b hover:border-gray-950 cursor-pointer`}>Read the Stories</p>
          </div>

          <div className='hidden 2xl:flex xl:flex lg:flex md:flex' onClick={() => setActiveNav('our-partners')}>
            <p className={activeNav === 'our-partners' ? `text-blue-900 border-b-4 border-blue-900 font-semibold` : `hover:border-b hover:border-gray-950 cursor-pointer`}>Our Partners</p>
          </div>

          <div className='hidden 2xl:flex xl:flex lg:flex md:flex' onClick={() => setActiveNav('tech-fest')}>
            <p className={activeNav === 'tech-fest' ? `text-blue-900 border-b-4 border-blue-900 font-semibold` : `hover:border-b hover:border-gray-950 cursor-pointer`}>Legaltech fest</p>
          </div>
        {/* <div> */}

        <div className='support_button w-[15%] lg:flex md:flex hidden' onClick={() => setActiveNav('support')}>
          <button className='bg-blue-800 py-2 rounded-xl px-4 text-white'>
            Support
          </button>
        </div>

        <div className='mobile-dropdown flex lg:hidden md:hidden xl:hidden 2xl:hidden' onClick={() => setMobileNav(!mobileNav)}>
          {mobileNav ? <div className='flex items-center gap-2'> <p>Menu</p><MdOutlineCancel size={30} /> </div>: <FaBars size={30} />}
        </div>
      </div>
      <div className={mobileNav === true ? `bg-white w-full h-screen relative z-50 p-4 flex flex-col gap-5` : `hidden w-full`}>
        <div className='flex justify-between' onClick={() => {
          setActiveNav('share-story');
          setMobileNav(false);
          }}>
        <p className={activeNav === 'share-story' ? `text-blue-900 border-b-4 border-blue-900 font-semibold w-[35%]` : `hover:border-b hover:border-gray-950 cursor-pointer `}>Share your story</p>
        <RxCaretRight className='w-6 h-6'/>
        </div>

        <div className='flex justify-between' onClick={() => {
          setActiveNav('read-story');
          setMobileNav(false)
        }}
          >
        <p className={activeNav === 'read-story' ? `text-blue-900 border-b-4 border-blue-900 font-semibold w-[35%]` : `hover:border-b hover:border-gray-950 cursor-pointer`} >Read the Stories</p>
        <RxCaretRight className='w-6 h-6'/>
        </div>

        <div className='flex justify-between' onClick={() => {
          setActiveNav('our-partners');
          setMobileNav(false)
        }}
          >
        <p className={activeNav === 'our-partners' ? `text-blue-900 border-b-4 border-blue-900 font-semibold w-[35%]` : `hover:border-b hover:border-gray-950 cursor-pointer`} >Our Partners</p>
        <RxCaretRight className='w-6 h-6'/>
        </div>

        <div className='flex justify-between' onClick={() => {
          setActiveNav('tech-fest');
          setMobileNav(false)
        }}
          >
        <p className={activeNav === 'tech-fest' ? `text-blue-900 border-b-4 border-blue-900 font-semibold w-[35%]` : `hover:border-b hover:border-gray-950 cursor-pointer`} >Legal Tech Fest</p>
        <RxCaretRight className='w-6 h-6'/>
        </div>

        <div className='support_button w-full' onClick={() => {
          setActiveNav('support');
          setMobileNav(false)
        }}>
          <button className='bg-blue-800 py-2 w-full rounded-xl px-4 text-white'>
            Support
          </button>
        </div>
      </div>
    </div>
  )
}

export default Nav